import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

const listactions = require('../../data.static/data-format-actions.json').data

export default function FormatsActionsModule (props) {
  
  const navigate = useNavigate()
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  
  const [loader, setLoader] = useState(true)
  const [data, setData] = useState()

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        const actionsList = props?.actions || []
        const result = [] //listactions.filter(x => x.user.includes(asset.role))
        setData([...result, ...actionsList])
        setLoader(false)
      }
      fetchData()
    }
  }, [])

  const handleClick = (live, link) => {
    if (live) navigate(`/${asset.role}/${link}`)
  }

  if (loader) return <></>

  return (
    <>
      <div className='back-color-wite rounded-xd p-1'>
        {data &&
          data.map((item, i) =>
            item.actv ? (
              <div className={``} key={i}>
                <div
                  className={`d-flex p-2 mt-1 mb-1 align-middle 
          ${item.live ? 'cursor hidark rounded-wd' : 'text-color-tint'}`}
                  style={{ height: '2.4rem' }}
                  onClick={() => handleClick(item.live, item.link)}
                >
                  <div className=''>
                    <i
                      className={`m-0 p-0 ${item.icon} `}
                      style={{ fontSize: '1.5rem' }}
                    ></i>
                  </div>
                  <div className='ms-2'>
                    <p className={`m-0 p-0 text-nowrap`}>
                      <span className=' align-middle'>
                        {item.name}
                      </span>
                    </p>
                  </div>
                  <div className='ms-auto '>
                    <i
                      className={`m-0 p-0 bx bx-chevron-right`}
                      style={{ fontSize: '1.5rem' }}
                    ></i>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )
          )}
      </div>
    </>
  )
}
