import axios from 'axios'
import services from '../data.services/data-services-main.json'

const base = services.data.find(x => x.code == 'transfers.data').link

export const TransfersDataStatistics = async item => {

  const basx = `${base}/transfers/data/statistics`
  const head = {
    'Content-Type': 'application/json',
    Authorization: process.env.REACT_APP_WEBB_SITE_CLNT
  }
  const datx = { data: item.data, srvc: process.env.REACT_APP_WEBB_SITE_SRVC }

  try {
    const result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}
