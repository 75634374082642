import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import ContentFormat from '../content/webz/content-format-xv'
import WebbIcon from '../content/webx/webb-icon'
import WebbHeader from '../content/webz/webb-header-navs-xv'
import UserAvatar from '../content/webx/user-avatar'
import WebbFooterMobile from '../content/webz/webb-footer-mobile'

import WebbDividerMedium from '../content/webx/webb-divider-md'
import WebbDividerSmall from '../content/webx/webb-divider-sm'
import WebbLoaderSmall from '../content/webx/webb-loader-sm'

import { GetUserForm } from '../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../services/srvc-auth-local'
import FormatsViewConsumerModule from '../content/formats/formats-view'
import FormatsViewSidebar from '../content/formats/formats-view-sidebar'
import FormatCodeModule from '../content/formats/format-code'
import FormatsActionsModule from '../content/formats/formats-actions-consumer'
import BackButtonModule from '../content/sidebar/back-button'
import FloatButton from '../content/float/float-button'

export default function FormatsViewConsumer () {

  const metadata = {
    name: 'Formats Details',
    banner: {
      link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'
    }
  }

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  const { id } = useParams()
  const [data, setData] = useState()
  const actionList = [
    {
      name: 'Create Transfer',
      memo: 'Format',
      icon: 'bx bx-wallet-alt',
      avtr: '',
      link: `transfers/create?format=${id}`,
      user: ['user', 'team'],
      live: true,
      actv: true
    }
  ]
  const handleData = async item => {
    setData(item)
  }

   const actionProps = {
     actions: actionList,
   };

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: "xtra", show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`${asset.role}/home`}>
                <WebbIcon
                  data={{ color: "text-color-main", size: "text-icon-sm" }}
                />
              </Link>
            </>
          ),
          data: (
            <>
              {" "}
              <WebbHeader />{" "}
            </>
          ),
          footer: (
            <>
              <UserAvatar />
            </>
          ),
        }}
        sidebar={{
          header: (
            <>
              <p className="text-normal text-bold m-0">{metadata.name}</p>
            </>
          ),
          data: (
            <>
              <FormatsViewSidebar {...data} />
            </>
          ),
          footer: <>...</>,
        }}
        content={{
          header: (
            <>
              <BackButtonModule heading="Digital Format Details" />
            </>
          ),
          data: (
            <>
              <FormatsViewConsumerModule handleData={handleData} />
              <WebbDividerMedium />
              <div className="d-md-none d-block">
                <FormatsViewSidebar {...data} />
                <FormatCodeModule />
              </div>
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <FloatButton
                action={FormatsActionsModule}
                actionProps={actionProps}
              />
            </>
          ),
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: (
            <>
              <FormatsActionsModule actions={actionList} />
              <WebbDividerSmall />
              <FormatCodeModule />
            </>
          ),
          footer: <></>,
        }}
      ></ContentFormat>
    </>
  );
}
