// main
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import ContentFormat from '../content/webz/content-format-xv'

import WebbIcon from '../content/webx/webb-icon'
import WebbHeader from '../content/webz/webb-header-navs-xv'
import UserAvatar from '../content/webx/user-avatar'

import WebbFooterMobile from '../content/webz/webb-footer-mobile'

import WebbDividerMedium from '../content/webx/webb-divider-md'
import WebbDividerSmall from '../content/webx/webb-divider-sm'
import WebbLoaderSmall from '../content/webx/webb-loader-sm'

import { GetUserForm } from '../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../services/srvc-auth-local'

import TeamInfocardHomeModule from '../content/user/team-infocard-home'

import TeamStatisticsModule from '../content/home/team-activiti-statistics'
import TeamTransferStatisticsModule from '../content/home/team-transfers-statistics'
import UserActionsModule from '../content/home/user-actions'
import MobileSidebarModule from '../content/sidebar/sidebar-menu'
import FloatButton from '../content/float/float-button'

export default function HomeTeam () {
  
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx
  console.log('user', asset)
  const metadata = {
    name: 'Home',
    banner: {
      link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'
    }
  }

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: "xtra", show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`${asset.role}/home`}>
                <WebbIcon
                  data={{ color: "text-color-main", size: "text-icon-sm" }}
                />
              </Link>
            </>
          ),
          data: (
            <>
              {" "}
              <WebbHeader />{" "}
            </>
          ),
          footer: (
            <>
              <UserAvatar />
            </>
          ),
        }}
        sidebar={{
          header: (
            <>
              <p className="text-normal text-bold m-0">{metadata.name}</p>
            </>
          ),
          data: (
            <>
              <TeamInfocardHomeModule />
            </>
          ),
          footer: <>...</>,
        }}
        content={{
          header: (
            <>
              <MobileSidebarModule heading="Overview" />
            </>
          ),
          data: (
            <>
              <h2 className="text-normal text-color-next mb-2 d-none">
                Account Activity
              </h2>
              {/* <TeamStatisticsModule /> */}

              {/* <WebbDividerMedium /> */}
              <h2 className="text-normal text-color-next mb-2">
                Account Activity
              </h2>
              <TeamTransferStatisticsModule />

              <WebbDividerMedium />
              <h2 className="text-normal text-color-next mb-2">
                Pending Transfers
              </h2>

              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <FloatButton action={UserActionsModule} />
            </>
          ),
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: (
            <>
              <UserActionsModule />
            </>
          ),
          footer: <></>,
        }}
      ></ContentFormat>
    </>
  );
}
