import React from "react";
import UserAvatar from "../webx/user-avatar";
import WebbIconBack from "../webx/webb-icon-back";
import { useNavigate } from "react-router-dom";

const BackButtonModule = (props) => {
  const navigate = useNavigate();
  return (
    <div className="d-flex justify-content-between justify-content-md-start align-items-start">
      <div className="d-flex gap-3">
        <div
          className="cursor d-md-none d-block"
          style={{ position: "relative", bottom: "5px" }}
          onClick={() => navigate(-1)}
        >
          <WebbIconBack
            data={{ color: "text-color-tone", size: "text-icon-sm" }}
          />
        </div>
      </div>
      <div>
        <p className="m-0">{props.heading}</p>
      </div>
      <div
        className="d-block d-md-none"
        style={{ position: "relative", bottom: "6px" }}
      >
        <UserAvatar />
      </div>
    </div>
  );
};

export default BackButtonModule;
