import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";

const FilterModule = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const ActionComponent = props.action;
  const actionProps = props.actionProps; 

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="text-normal text-color-next mt-2">{props.heading}</h2>
        <div
          className="d-flex align-items-center d-md-none gap-1 cursor"
          onClick={handleShow}
        >
          Filter
          <i className="bx bx-slider-alt" style={{ fontSize: "16px" }}></i>
        </div>
      </div>

      <Modal className="p-4" show={show} onHide={handleClose} centered>
        <Modal.Header style={{padding:"10px 15px"}} closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ActionComponent {...actionProps} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FilterModule;
