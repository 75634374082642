import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";

const FloatButton = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const ActionComponent = props.action;
  const actionProps = props.actionProps;

  return (
    <>
      {/* <Draggable> */}
      <button
        variant="primary"
        onClick={handleShow}
        className="d-flex justify-content-center align-items-center border-0 d-md-none"
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          fontSize: "24px",
          backgroundColor: "#304FFE",
          color: "white",
          zIndex: "10",
        }}
      >
        +
      </button>
      {/* </Draggable> */}
      <Modal className="p-4" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton style={{ padding: "10px 15px" }}>
          <Modal.Title>Actions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ActionComponent {...actionProps} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FloatButton;
