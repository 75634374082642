// main
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xv";

import WebbIcon from "../content/webx/webb-icon";
import WebbHeader from "../content/webz/webb-header-navs-xv";
import UserAvatar from "../content/webx/user-avatar";

import WebbFooterMobile from "../content/webz/webb-footer-mobile";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import WebbLoaderSmall from "../content/webx/webb-loader-sm";

import { GetUserForm } from "../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../services/srvc-auth-local";

import MenuActionsModule from "../content/user/menu-actions";

import UserAccountsListModule from "../content/accounts/accounts-list";
import TeamListModule from "../content/network.team/team-list";
import MobileSidebarModule from "../content/sidebar/sidebar-menu";
import TeamInfocardHomeModule from "../content/user/team-infocard-home";

const list = [
  { name: "overview", code: "overview", actv: true },
  { name: "accounts", code: "accounts", actv: true },
  { name: "team", code: "team", actv: true },
];

export default function HomeTeam() {
  const metadata = {
    name: "Account Details",
    banner: {
      link: "https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900",
    },
  };

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const [menu, setMenu] = useState("overview");

  const handleMenu = (item) => {
    console.log(item);
    setMenu(item);
  };

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: "xtra", show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`${asset.role}/home`}>
                <WebbIcon
                  data={{ color: "text-color-main", size: "text-icon-sm" }}
                />
              </Link>
            </>
          ),
          data: (
            <>
              {" "}
              <WebbHeader />{" "}
            </>
          ),
          footer: (
            <>
              <UserAvatar />
            </>
          ),
        }}
        sidebar={{
          header: (
            <>
              <p className="text-normal text-bold m-0">{metadata.name}</p>
            </>
          ),
          data: (
            <>
              <MenuActionsModule
                data={{ active: menu }}
                handleItem={handleMenu}
              />
            </>
          ),
          footer: <>...</>,
        }}
        content={{
          header: (
            <>
              <MobileSidebarModule heading="Details" />
            </>
          ),
          data: (
            <>
              <h2 className="text-normal text-color-next d-none d-md-block">
                {menu}
              </h2>
              <WebbDividerSmall />
              <div
                className={menu == "overview" ? "d-md-block d-none" : "d-none"}
              >
                <TeamInfocardHomeModule />
              </div>

              <div
                className={menu == "accounts" ? "d-md-block d-none" : "d-none"}
              >
                <UserAccountsListModule />
              </div>

              <div className={menu == "team" ? "d-md-block d-none" : "d-none"}>
                <TeamListModule />
              </div>

              {/* mobile view */}
              <div className="d-md-none d-block">
                <h2 className="text-normal text-color-next mb-2">Overview</h2>
                <TeamInfocardHomeModule />
                <WebbDividerMedium />
                <h2 className="text-normal text-color-next mb-2">Accounts</h2>
                <UserAccountsListModule />
                <WebbDividerMedium />
                <h2 className="text-normal text-color-next mb-2">Team</h2>
                <TeamListModule />
              </div>

              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          ),
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: <></>,
          footer: <></>,
        }}
      ></ContentFormat>
    </>
  );
}
