import { QRCodeCanvas } from 'qrcode.react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GetLocalBusiness, GetLocalUser } from '../../services/srvc-auth-local'
import { GetUserForm } from '../../services/srvc-utilities'
import { DwonloadFormatSticker } from '../../services/srvc-aws-lambda'
import { FormatsDetails } from '../../services/srvc-formats-realm'

export default function FormatCodeModule (props) {
  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const { id } = useParams()

  const [loader, setLoader] = useState(true)
  const [data, setData] = useState()
  const [details, setDetails] = useState()
  const [load, setLoad] = useState(false)

  

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        setData(props?.data || { id: id })
        // console.log(props.data)

        const res = await FormatsDetails({
          data: { item: id, consumer: asset.item }
        })
        setDetails(res.data)
        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [props])


  // console.log(details)

  const downloadQRStricker = async()=>{
    setLoad(true)
    var datx = {
    "consumerName":details?.consumer.name,
    "consumerNumber":asset.number,
    "formatName":details?.name || '',
    "CONSUMER.CODE":"",
    "qrLink":`https://mint.nuvo.bi/dm/${id}`
    }
    var res = await DwonloadFormatSticker({data:datx})
    // console.log(res)
    if(res.stat){
      downloadPDF(res.data.base64)
      setLoad(false)
    }
    if(!res.stat){
      alert("error: internal server error")
    }
  }

  function downloadPDF(base64String, fileName = 'format.pdf') {
    // Decode the base64 string to a byte array
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
  
    // Convert the byte array to a blob
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
  
    // Create a link element and trigger a download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
  
    // Clean up by removing the link element and revoking the object URL
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  }
  

  const handleClick = item => {
    // window.location.)
    // if (live) navigate(`/${asset.role}/${link}`)
  }

  if (loader) return <></>

  return (
    <>
      <div
        className='back-color-wite text-center p-3 rounded-xd'
        // onClick={() => handleClick(data.id)}
      >
        <a
          className='cursor'
          href={`https://mint.nuvo.bi/dm/${data?.id}`}
          target='_blank'
          rel='noopener'
        >
          <QRCodeCanvas
            // value={`iam.asset.${data?.id}`}
            value={`https://mint.nuvo.bi/dm/${data?.id}`}
            size={'128'}
            includeMargin={false}
          />
        </a>
        <p onClick={()=>downloadQRStricker()} className='text-primary cursor' >{load? 'please wait...':'Download'}</p>
      </div>
    </>
  )
}
